.container {
  background-color: #fff;
  padding: 1.5em;
  border-radius: 15px;
  margin-bottom: 2em;
}

.block {
  background-color: #f5f5f6;
  border-radius: 5px;
}

.blockNoColor {
  border-radius: 5px;
}

.gaps {
  gap: 1em;
}

.logo {
  max-width: 120px;
  max-height: 120px;
  border-radius: 5px;
}
