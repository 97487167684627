.bundleMenuBlock.active {
  opacity: 1;
}

.bundleMenuBlock {
  opacity: 0.8;
  cursor: pointer;
}

.bundleMenuBlock:hover {
  opacity: 1;
}
