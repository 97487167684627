.disabled {
  opacity: 0.5;
}

body {
  background-color: #efefef;
}

body h1 {
  font-size: 1.4em;
  margin: 0px;
  padding: 5px 5px 5px 0px;
}

body h2 {
  font-size: 1.2em;
  margin: 0px;
  padding: 5px 5px 5px 0px;
}

body h3 {
  font-size: 1.2em;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}

body h4 {
  font-size: 1em;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}

#assessment {
  height: 100%;
  background: #fff;
}
